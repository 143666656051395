.App {
  text-align: center;
}

.App-header {
  height: 100%;
}

.cap {
  text-transform: capitalize;
}

.App-link {
  color: #61dafb;
}

.bsd-navWrapper-details .bsd-button {
  font-family: Montserrat,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1.5px;
    display: block;
    width: 25%;
    min-width: 8.75em;
    padding: 15px 20px;
    margin:10px;
    cursor: pointer;
    background-color: #0d62ff;
    color:white;
    border:0px;
    border-radius:unset;
}

.bsd-navWrapper-details .bsd-button-containers {
  display: flex;
  justify-content:center;
  align-items: center;
}
