body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 90%;
  margin: 0 auto;
  padding: 50px 0;
}

.bsd-navWrapper-details {
  color: #112f64;
  font-family: Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
}

.bsd-container {
  display: flex;
  flex-direction: COLUMN;
}
